import { cx } from "cva";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";
import { ErrorBoundary } from "~/components/ErrorBoundary";

export interface FormattedDateProps {
  date: Date;
  includeTZ?: Boolean;
  className?: string;
  dateFormat?: string;
}

const FormatAndRenderData = ({
  date,
  includeTZ,
  className,
  dateFormat = "MMMM d, yyyy",
  ...rest
}: FormattedDateProps): JSX.Element => {
  const dateString = useMemo((): string => {
    if (!includeTZ) {
      return format(date, dateFormat);
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneLabel = new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    return formatInTimeZone(
      date,
      timezone,
      `MMMM dd, yyyy 'at' h:mm aaa '${timezoneLabel}'`
    );
  }, [includeTZ, date, dateFormat]);

  return (
    <div className={cx(className)} {...rest}>
      {dateString}
    </div>
  );
};

/**
 * Extra caution here since date formatting can throw at times
 * given vagaries of formatting dates in local timezones.
 */
export const FormattedDate = (props: FormattedDateProps): JSX.Element => (
  <ErrorBoundary
    fallback={<></>}
    context={{ ...props, component: "FormattedDate" }}
  >
    <FormatAndRenderData {...props} />
  </ErrorBoundary>
);
