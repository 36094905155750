import Image from "next/image";
import Link from "next/link";
import { cva } from "cva";
import { constants } from "~/common/constants";
import { ContentItem, ContentItemProps } from "~/components/ContentItem";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";

export type ContentItemWithImageProps = {
  /** class name added to the container of both the image and content item.  */
  containerClassName?: string;
  /** class name added to the underlying ContentItem component */
  className?: string;
  imagePosition?: "left" | "top" | "right";
  imageSize?: "grow";
  imageSrc: string;
  imageAlt?: string;
  noImageOnShrink?: boolean;
} & ContentItemProps;

const wrapperStyles = cva(["flex", "flex-1", "flex-col", "gap-y-s"], {
  defaultVariants: {
    hasResponsiveHeadline: true,
  },
  variants: {
    imagePosition: {
      left: ["md:flex-row", "md:gap-x-m", "md:gap-y-0"],
      top: [],
      right: ["md:flex-row-reverse", "md:gap-x-m", "md:gap-y-0"],
    },
    imageSize: {
      grow: "",
    },
    asHero: {
      true: "",
      false: "",
    },
    hasResponsiveHeadline: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      asHero: true,
      hasResponsiveHeadline: true,
      imagePosition: "top",
      className: [
        "md:flex-row",
        "md:gap-x-m",
        "md:gap-y-0",
        "lg:flex-col",
        "lg:gap-y-s",
      ],
    },
  ],
});

const imageStyles = cva(["relative", "overflow-hidden", "aspect-16/9"], {
  variants: {
    imagePosition: {
      left: ["min-w-[250px]"],
      top: [],
      right: ["min-w-[250px]"],
    },
    imageSize: {
      grow: "w-full",
    },
    asHero: {
      true: "",
      false: "",
    },
    noImageOnShrink: {
      true: "hidden lg:block",
      false: "",
    },
  },
  compoundVariants: [
    {
      asHero: true,
      imagePosition: "top",
      className: "!block sm:min-w-[250px]",
    },
  ],
});

export const ContentItemWithImage: React.FC<ContentItemWithImageProps> = ({
  containerClassName,
  hasResponsiveHeadline,
  imageSize,
  imageSrc,
  imagePosition = "left",
  imageAlt = "",
  headline,
  headlineHref,
  asHero,
  noImageOnShrink,
  ...contentItemProps
}) => (
  <div
    className={extendedTwMerge(
      wrapperStyles({
        hasResponsiveHeadline,
        imagePosition,
        imageSize,
        asHero,
      }),
      containerClassName
    )}
  >
    {headlineHref ? (
      <Link
        className={imageStyles({
          imagePosition,
          imageSize,
          asHero,
          className: "block",
          noImageOnShrink,
        })}
        href={headlineHref}
        data-event-tree-target={
          constants.EventTreeTarget.CONTENT_ITEM_IMAGE_LINK
        }
        data-analytics-headline={headline}
        aria-label={`Links to "${headline}"`}
      >
        <Image
          src={imageSrc}
          alt={imageAlt}
          quality={100}
          fill
          className="object-cover"
        ></Image>
      </Link>
    ) : (
      <div
        className={imageStyles({
          imagePosition,
          imageSize,
          asHero,
          noImageOnShrink,
        })}
      >
        <Image
          src={imageSrc}
          alt={imageAlt}
          quality={100}
          fill
          className="object-cover"
        ></Image>
      </div>
    )}
    <ContentItem
      {...contentItemProps}
      asHero={asHero}
      hasResponsiveHeadline={hasResponsiveHeadline}
      headline={headline}
      headlineHref={headlineHref}
    />
  </div>
);
