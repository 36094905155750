import { Body, BodyProps } from "~/ui-library/components/Body";
import { Icon, IconProps } from "~/ui-library/components/Icon";
import { ChartPackIcon } from "~/ui-library/icons";

export type ChartPackLabelProps = {
  className?: string;
  bodySize: BodyProps["size"];
  iconSize: IconProps["size"];
};

export const ChartPackLabel: React.FC<ChartPackLabelProps> = ({
  bodySize,
  className,
  iconSize,
}) => (
  <Body className={className} size={bodySize}>
    <span>Chart Pack</span>
    <Icon
      className="ml-xxs inline fill-transparent stroke-gray-800"
      size={iconSize}
      SvgIcon={ChartPackIcon}
    />
  </Body>
);
