import { KeyboardEventHandler, MouseEventHandler } from "react";
import { EventTreeTarget } from "~/common/constants";
import { valueIsEnum } from "~/lib/shared/typeUtilities";

export const createClickEventTreeHandler =
  <T extends HTMLElement>(
    matchingEventTreeTargets: EventTreeTarget[],
    handler: (
      matchingOrigin: EventTreeTarget,
      matchingElement: HTMLElement
    ) => void
  ) =>
  (event: Parameters<MouseEventHandler<T>>[0]) => {
    let currentEl: HTMLElement | null = event.target as HTMLElement;
    while (currentEl) {
      const target = currentEl.getAttribute("data-event-tree-target");
      if (
        valueIsEnum(target, EventTreeTarget) &&
        matchingEventTreeTargets.includes(target)
      ) {
        handler(target, currentEl);
        currentEl = null;
      } else {
        currentEl = currentEl.parentElement;
      }
    }
  };

export const createKeyboardEventTreeHandler =
  <T extends HTMLElement>(
    matchingEventTreeTargets: EventTreeTarget[],
    matchingKeyCodes: KeyboardEvent["code"][],
    handler: (
      matchingOrigin: EventTreeTarget,
      matchingElement: HTMLElement,
      matchingKeyCode: string
    ) => void
  ) =>
  (event: Parameters<KeyboardEventHandler<T>>[0]) => {
    let currentEl: HTMLElement | null = event.target as HTMLElement;
    if (!matchingKeyCodes.includes(event.code)) {
      return;
    }
    while (currentEl) {
      const target = currentEl.getAttribute("data-event-tree-target");
      if (
        valueIsEnum(target, EventTreeTarget) &&
        matchingEventTreeTargets.includes(target)
      ) {
        handler(target, currentEl, event.code);
        currentEl = null;
      } else {
        currentEl = currentEl.parentElement;
      }
    }
  };
