import { ReactNode } from "react";
import { cva, VariantProps } from "cva";
import { Headline } from "~/ui-library";
import Link from "next/link";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";

const listStyle = cva(
  [
    // set the display to grid
    "grid",
    // set the horizontal gap to medium. This does not impact single column layout!
    "gap-x-m",
  ],
  {
    defaultVariants: {
      variant: "column",
      itemBorder: true,
      gutters: true,
    },
    variants: {
      variant: {
        column: "grid-cols-1",

        "1-column-to-2-column-desktop": [
          // 1 column grid mobile
          "grid-cols-1",
          // 2 column grid medium and up
          "md:grid-cols-2",
        ],

        "1-column-to-2-column-med-only": [
          // 1 column grid mobile
          "grid-cols-1",
          // 2 column grid medium and up
          "md:grid-cols-2",
          // on medium, remove the bottom padding and margin of the last two items
          "[&>*:nth-last-child(-n+2)]:md:mb-0 [&>*:nth-last-child(-n+2)]:md:pb-0",
          // 1 column grid large and up
          "lg:grid-cols-1",
          // on large re-add the bottom padding and margin
          "[&>*:nth-last-child(-n+2)]:lg:mb-s [&>*:nth-last-child(-n+2)]:lg:pb-s",
          // and remove it for the last item
          "[&>*:nth-last-child(-n+1)]:lg:mb-0 [&>*:nth-last-child(-n+1)]:lg:pb-0",
        ],
        "3-item-row": [
          // 1 column grid mobile
          "grid-cols-1",
          // 3 column grid medium and up
          "md:grid-cols-3",
          // on medium remove the bottom margin and padding from everything
          "[&>*]:md:mb-0 [&>*]:md:pb-0",
        ],
        "3-2-1-item-row": [
          // 1 column grid small
          "grid-cols-1",
          // 2 column grid md
          "md:grid-cols-2",
          // 3 column grid large and up
          "lg:grid-cols-3",
          // on medium remove the bottom margin and padding from everything
          "[&>*]:md:mb-0 [&>*]:md:pb-0",
        ],
        "4-item-row": [
          // 1 column grid mobile
          "grid-cols-1",
          // 2 column grid medium and up
          "md:grid-cols-2",
          // on medium, remove the bottom padding and margin of the last two items
          "[&>*:nth-last-child(-n+2)]:md:mb-0 [&>*:nth-last-child(-n+2)]:md:pb-0",
          // 4 column grid on large
          "lg:grid-cols-4",
          // on large remove the bottom margin and padding from everything
          "[&>*]:lg:mb-0 [&>*]:lg:pb-0",
        ],
        "4-item-grid-to-column": [
          // 1 column grid mobile
          "grid-cols-1",
          // 2 column grid medium and up
          "md:grid-cols-2",
          // on medium, remove the bottom padding and margin of the last two items
          "[&>*:nth-last-child(-n+2)]:md:mb-0 [&>*:nth-last-child(-n+2)]:md:pb-0",
        ],
      },
      gutters: {
        true: [
          // add small top and bottom padding to all child items in the grid
          "[&>*]:mb-s [&>*]:pb-s",
          // except for the last item
          "[&>*:last-child]:mb-0 [&>*:last-child]:pb-0",
        ],
        false: [],
      },
      itemBorder: {
        true: [
          // set the border width to 1px
          "[&>*]:border-b-1",
          // set the border color to gray.
          "[&>*]:border-gray-300",
          // set the last child item in the grid to have no border. This ensures single column layouts don't have a bottom border.
          "[&>*:last-child]:border-b-0",
        ],
        false: [],
      },
    },
    compoundVariants: [
      {
        variant: "1-column-to-2-column-med-only",
        itemBorder: true,
        className: [
          // on medium, remove the bottom border of the last two items to prevent a bottom border on our grid
          "[&>*:nth-last-child(-n+2)]:md:border-b-0",
          // on large and up we're back to a single column so re-add the border
          "[&>*:nth-last-child(-n+2)]:lg:border-b-1",
          // and remove it again for the last child
          "[&>*:nth-last-child(-n+1)]:lg:border-b-0",
        ],
      },
      {
        variant: "3-item-row",
        itemBorder: true,
        className: [
          // set the border on medium and up to none
          "[&>*]:md:border-b-0",
        ],
      },
      {
        variant: "4-item-row",
        itemBorder: true,
        className: [
          // on medium, remove the bottom border of the last two items to prevent a bottom border on our grid
          "[&>*:nth-last-child(-n+2)]:md:border-b-0",
          // on large, no bottom border for 4 column layout
          "[&>*]:lg:border-b-0",
        ],
      },
      {
        variant: "4-item-grid-to-column",
        itemBorder: true,
        className: [
          // on medium, remove the bottom border of the last two items to prevent a bottom border on our grid
          "[&>*:nth-last-child(-n+2)]:md:border-b-0",
        ],
      },
    ],
  }
);

const titleStyle = cva(null, {
  defaultVariants: {
    titleBorder: false,
  },
  variants: {
    titleBorder: {
      true: "mb-s border-b-1 border-gray-300 pb-xs",
      false: "mb-s md:mb-m",
    },
  },
});

const renderTitle = (
  titleBorder: boolean,
  title?: string | null,
  titleHref?: string
) => {
  if (title && titleHref) {
    return (
      <Link
        href={titleHref}
        className={titleStyle({ titleBorder, className: "block" })}
      >
        <Headline
          level={5}
          as="h2"
          className="uppercase tracking-headline1-block text-gray-800"
        >
          {title}
        </Headline>
      </Link>
    );
  }

  if (title) {
    return (
      <div className={titleStyle({ titleBorder })}>
        <Headline
          level={6}
          as="h2"
          className="uppercase tracking-headline1-block text-gray-800"
        >
          {title}
        </Headline>
      </div>
    );
  }

  return null;
};

export type ContentItemGridProps = {
  children: ReactNode;
  className?: string;
  title?: string | null;
  titleHref?: string;
} & VariantProps<typeof listStyle> &
  VariantProps<typeof titleStyle>;

export const ContentItemGrid: React.FC<ContentItemGridProps> = ({
  children,
  className,
  itemBorder,
  variant,
  title,
  titleHref,
  titleBorder,
}) => (
  <>
    {renderTitle(Boolean(titleBorder), title, titleHref)}
    <div
      className={extendedTwMerge(listStyle({ className, itemBorder, variant }))}
    >
      {children}
    </div>
  </>
);
